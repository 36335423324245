<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>รายการเอกสารที่ต้องการลบ <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <div class="text-center">
            <v-chip class="pt-4 pl-2 ma-2"
              ><p>
                <v-icon :color="color.theme"> folder </v-icon><b> ไฟล์ที่เลือก {{ count_item }} ไฟล์</b>
              </p></v-chip
            >
          </div>
          <v-list>
            <!--โชว์ไฟล์สามารถลบได้ -->
            <v-list-item v-for="item in showdelete" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large color="primary">delete</v-icon>
              </v-list-item-action>
              <v-badge :value="item.show_name" color="grey" :content="item.file_name" transition="slide-y-transition"></v-badge>
              <v-list-item-content>
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ลบหรือไม่-->
              <!-- <v-list-item-action v-if="check_show_progress === false">
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                 {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.cannotdelete') }}
                </v-chip>
              </v-list-item-action> -->
              <!-- โชว์สถานะการลบว่าลบสำเร็จหรือไม่ -->
              <v-list-item-action>
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="color.theme" label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'), $emit('closebuttonmulti'), file_data = []"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false"
            :dark="color.darkTheme"
            @click="fn_multidelete(), (check_show_button = true), (check_show_progress = true)"
            >{{ $t("dialogmultidelete.buttondelete") }}</v-btn
          >
          <!-- ลบเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn
            color="red"
            :disabled="check_dis_button"
            v-if="check_show_button === true"
            outlined
            @click="$emit('closedialogreload'), $emit('closebuttonmulti'), file_data = []"
            >{{ $t("dialogmultidelete.buttonclose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "filedata"],
  data: function() {
    return {
      file_data: [],
      check_dis_button: true,
      check_show_progress: false,
      check_show_button: false,
      showdelete: [],
      showcannotdelete: [],
      count_item: 0,
      countshowdelete: 0,
      countshowcannotdelete: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("filedata", this.filedata);
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.$emit("closeDrag");
        this.fn_loaddata();
        this.check_show_button = false;
        console.log(this.filedata);
      } else {
        this.$emit("openDrag");
        this.check_show_progress = false;
        this.showdelete = [];
        this.showcannotdelete = [];
        this.count_item = 0;
      }
    },
  },
  methods: {
    fn_loaddata() {
      if (this.$router.app["_route"]["name"] === "myinbox_sourcesystem" || this.$router.app["_route"]["name"] === "myoutbox_sourcesystem") {
        this.file_data.push(this.filedata);
        console.log("file_data[]---", this.file_data);

        for (let i = 0; i < this.file_data.length; i++) {
          this.count_item++;
          console.log("++++", this.file_data[i]);
          let datadelete = {};
          datadelete["file_name"] = this.file_data[i]["inbox_filename"];
          datadelete["file_id"] = this.file_data[i]["inbox_id"];
          datadelete["file_icon"] = this.file_data[i]["file_icon"];
          datadelete["file_status"] = "มีสิทธิ์ลบไฟล์";
          datadelete["file_value"] = 0;
          datadelete["show_name"] = false;
          this.showdelete.push(datadelete);
        }
        this.countshowdelete = this.showdelete.length;
        console.log("showdelete", this.showdelete);
      } else {
        this.file_data.push(this.filedata);
        // console.log("filedata---",this.filedata);
        console.log("file_data[]---", this.file_data);

        for (let i = 0; i < this.file_data.length; i++) {
          this.count_item++;
          console.log("++++", this.file_data[i]);
          let datadelete = {};
          datadelete["file_name"] = this.file_data[i]["file_name"];
          datadelete["file_id"] = this.file_data[i]["file_id"];
          datadelete["file_icon"] = this.file_data[i]["file_icon"];
          datadelete["file_status"] = "มีสิทธิ์ลบไฟล์";
          datadelete["file_value"] = 0;
          datadelete["show_name"] = false;
          this.showdelete.push(datadelete);
        }
        this.countshowdelete = this.showdelete.length;
        console.log("showdelete", this.showdelete);
      }
    },
    // //ฟังก์ชั่นวนลบไฟล์
    async fn_multidelete() {
      if (this.$router.app["_route"]["name"] === "myfiles") {
        for (let i = 0; i < this.file_data.length; i++) {
          let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.file_data[i].file_id);
          console.log("objIndex", objIndex);
          //เช็คเพื่อนวนส่งข้อมูลเฉพาะไฟล์ที่มีสิทธิ์ลบ
          this.showdelete[objIndex]["file_status"] = "กำลังลบไฟล์";
          let payload = {
            account_id: this.file_data[i]["account_sender"],
            user_id: this.dataUsername,
            data_id: this.file_data[i]["file_id"],
            data_type: this.file_data[i]["file_type"] === "folder" ? "folder" : "file",
            status_data: "T",
          };
          console.log("payload", payload);
          await this.fn_delete(payload, this.file_data[i].file_id).then(async (res) => {
            if (res.status === "delete success") {
              console.log("yess");
            } else {
              console.log("nooo");
            }
          });
          if (this.file_data.length - i === 1) {
            this.check_dis_button = false;
          }
        }
      } else if (this.$router.app["_route"]["name"] === "directory") {
        for (let i = 0; i < this.file_data.length; i++) {
          let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.file_data[i].file_id);
          console.log("objIndex", objIndex);
          //เช็คเพื่อนวนส่งข้อมูลเฉพาะไฟล์ที่มีสิทธิ์ลบ
          this.showdelete[objIndex]["file_status"] = "กำลังลบไฟล์";
          let status_data = "T";
          if (this.dataAccountActive["type"] === "Citizen") {
            status_data = "T";
          } else {
            if (this.role_level == "True") {
              // admin
              status_data = "TB";
            } else {
              status_data = "T";
            }
          }
          let payload = {
            account_id: this.file_data[i]["account_sender"],
            user_id: this.dataUsername,
            data_id: this.file_data[i]["file_id"],
            data_type: this.file_data[i]["file_type"] === "folder" ? "folder" : "file",
            status_data: status_data,
          };
          console.log("payload", payload);
          await this.fn_delete(payload, this.file_data[i].file_id).then(async (res) => {
            if (res.status === "delete success") {
              console.log("yess");
            } else {
              console.log("nooo");
            }
          });
          if (this.file_data.length - i === 1) {
            this.check_dis_button = false;
          }
        }
      } else if (this.$router.app["_route"]["name"] === "myinbox_sourcesystem") {
        for (let i = 0; i < this.file_data.length; i++) {
          let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.file_data[i].inbox_id);
          console.log("objIndex", objIndex);

          this.showdelete[objIndex]["file_status"] = "กำลังลบไฟล์";
          let payload = {
            inbox_id: [this.file_data[i]["inbox_id"]],
            account_id: this.dataAccountId,
          };
          console.log("payload", payload);
          await this.fn_delete(payload, this.file_data[i].inbox_id).then(async (res) => {
            if (res.status === "delete success") {
              console.log("yess");
            } else {
              console.log("nooo");
            }
          });
          if (this.file_data.length - i === 1) {
            this.check_dis_button = false;
          }
        }
      } else if (this.$router.app["_route"]["name"] === "myoutbox_sourcesystem") {
        for (let i = 0; i < this.file_data.length; i++) {
          let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.file_data[i].inbox_id);
          console.log("objIndex", objIndex);

          this.showdelete[objIndex]["file_status"] = "กำลังลบไฟล์";
          let payload = {
            outbox_id: [this.file_data[i]["inbox_id"]],
            account_id: this.dataAccountId,
          };
          console.log("payload", payload);
          await this.fn_delete(payload, this.file_data[i].inbox_id).then(async (res) => {
            if (res.status === "delete success") {
              console.log("yess");
            } else {
              console.log("nooo");
            }
          });
          if (this.file_data.length - i === 1) {
            this.check_dis_button = false;
          }
        }
      }
    },
    // //ลบไฟล์/โฟลเดอร์
    async fn_delete(payload, file_id) {
      console.log("delete");
      if (this.$router.app["_route"]["name"] === "myinbox_sourcesystem") {
        let objIndex = this.showdelete.findIndex((obj) => obj.file_id === file_id);
         let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_inbox",
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showdelete[objIndex]["file_value"] = 95;
            } else {
              this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showdelete[objIndex]["file_status"] = "ลบไฟล์สำเร็จ";
              this.showdelete[objIndex]["file_value"] = 100;
              setTimeout(() => {
                resolve({ status: "delete success", msg: "OK", code: "" });
              }, 500);
            } else {
              this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
              this.showdelete[objIndex]["file_value"] = 500;
              setTimeout(() => {
                resolve({ status: "delete denied", msg: ": ", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "delete denied", msg: ": " + err, code: "" });
            }, 500);
            this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
            this.showdelete[objIndex]["file_value"] = 500;
          }
        });
      }else if(this.$router.app["_route"]["name"] === "myoutbox_sourcesystem"){
        let objIndex = this.showdelete.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_outbox",
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showdelete[objIndex]["file_value"] = 95;
            } else {
              this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showdelete[objIndex]["file_status"] = "ลบไฟล์สำเร็จ";
              this.showdelete[objIndex]["file_value"] = 100;
              setTimeout(() => {
                resolve({ status: "delete success", msg: "OK", code: "" });
              }, 500);
            } else {
              this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
              this.showdelete[objIndex]["file_value"] = 500;
              setTimeout(() => {
                resolve({ status: "delete denied", msg: ": ", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "delete denied", msg: ": " + err, code: "" });
            }, 500);
            this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
            this.showdelete[objIndex]["file_value"] = 500;
          }
        });
      }else {
        let auth = await gbfGenerate.generateToken();
        let objIndex = this.showdelete.findIndex((obj) => obj.file_id === file_id);
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_data_to_trash", // เก่า /api/remove_file_folder_to_trash
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showdelete[objIndex]["file_value"] = 95;
            } else {
              this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showdelete[objIndex]["file_status"] = "ลบไฟล์สำเร็จ";
              this.showdelete[objIndex]["file_value"] = 100;
              setTimeout(() => {
                resolve({ status: "delete success", msg: "OK", code: "" });
              }, 500);
            } else {
              this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
              this.showdelete[objIndex]["file_value"] = 500;
              setTimeout(() => {
                resolve({ status: "delete denied", msg: ": ", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "delete denied", msg: ": " + err, code: "" });
            }, 500);
            this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
            this.showdelete[objIndex]["file_value"] = 500;
          }
        });
      }
    },
  },
};
</script>
<style></style>
